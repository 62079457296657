import { useContext } from 'react'

import { PlanSuggestionContext } from '../PlanSuggestionContext'

export const usePlanSuggestion = () => {
  const [state, setState] = useContext(PlanSuggestionContext)

  function setSpeed(index) {
    if (state.pageType === 'mobile') {
      const isMedium = [1, 2].includes(Number(index))
      const isLarge = [3, 4].includes(Number(index))
      const indexData = isMedium ? 1 : isLarge ? 2 : 0
      setState({
        ...state,
        ...{
          currentVoiceIndex:
            state.currentTypeIndex == 1 && index == 2 ? 3 : index,
          currentDataIndex: state.currentTypeIndex == 0 ? indexData : index,
        },
      })
    } else {
      setState({ ...state, currentSpeedIndex: index })
    }
  }

  function setType(index) {
    setState({ ...state, currentTypeIndex: index })
  }

  function setLength(index) {
    setState({ ...state, currentLengthIndex: index })
  }

  function currentSelectedPlan() {
    if (state.pageType === 'mobile') {
      if (state.currentTypeIndex === '1') {
        const selectedPlan = state.plans.filter(
          plan =>
            plan.speed === state.speedsDataOnly[state.currentDataIndex] &&
            plan.planType === state.types[state.currentTypeIndex]
        )
        return selectedPlan[0]
      } else {
        // setState({ ...state, currentDataIndex: idx })
        const selectedPlan = state.plans.filter(
          plan =>
            plan.speed === state.speedsVoiceDataOnly[state.currentVoiceIndex] &&
            plan.planType === state.types[state.currentTypeIndex]
        )
        return selectedPlan[0]
      }
    } else {
      if (state.currentTypeIndex === '1') {
        if (state.currentSpeedIndex === '4') {
          setState({ ...state, currentSpeedIndex: '3' })
        }
        const selectedPlan = state.plans.filter(
          plan =>
            plan.speed === state.speedsBusinessOnly[state.currentSpeedIndex] &&
            plan.planType === state.types[state.currentTypeIndex]
        )
        return selectedPlan[0]
      } else {
        const selectedPlan = state.plans.filter(
          plan =>
            plan.speed === state.speedsPersonalOnly[state.currentSpeedIndex] &&
            plan.planType === state.types[state.currentTypeIndex]
        )
        return selectedPlan[0]
      }
    }
  }

  return {
    plans: state.plans,
    speeds: state.speeds,
    types: state.types,
    lengths: state.lengths,
    loading: state.loading,
    currentSpeedIndex: state.currentSpeedIndex,
    currentDataIndex: state.currentDataIndex,
    currentVoiceIndex: state.currentVoiceIndex,
    currentTypeIndex: state.currentTypeIndex,
    currentLengthIndex: state.currentLengthIndex,
    pageType: state.pageType,
    speedsPersonalOnly: state.speedsPersonalOnly,
    speedsBusinessOnly: state.speedsBusinessOnly,
    speedsDataOnly: state.speedsDataOnly,
    speedsVoiceDataOnly: state.speedsVoiceDataOnly,
    setType,
    setSpeed,
    setLength,
    currentSelectedPlan,
  }
}
