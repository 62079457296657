import React, { useState } from 'react'

import { usePlanSuggestion } from './usePlanSuggestion'
import { ReadMoreModal } from '../../../mobile/ReadMoreModal'

export const PlanExclusions = () => {
  const [open, setOpen] = useState(false)
  const { pageType } = usePlanSuggestion()

  return (
    <div className="plan-notice container">
      <div className="row pt-3">
        <div className="col-md-12">
          {pageType === 'mobile' ? (
            <>
              <p className="text-center small">
                <strong>Exclusions:</strong> Premium services, international,
                and third party content.{' '}
                <a onClick={() => setOpen(true)}>
                  <u>Read more</u>
                </a>
                <br />
                All for use in Australia. Personal use only.{' '}
                <a href="/pdf/acceptable-use-policy-mobile.pdf" target="_blank">
                  Acceptable Use Policy
                </a>{' '}
                applies, refer to our{' '}
                <a href="/cis" target="_blank">
                  Critical Information Summaries
                </a>
                .
              </p>
              <p className="text-center small">
                <strong>One SIM card for every device:</strong> Our SIM fits any
                mobile device. Standard, micro or nano.
              </p>
              <ReadMoreModal
                openModal={open}
                closeModal={() => setOpen(false)}
              />
            </>
          ) : (
            <p className="text-center small">
              All for use in Australia.{' '}
              <a href="/pdf/acceptable-use-policy-nbn-voip.pdf" target="_blank">
                Acceptable Use Policy
              </a>{' '}
              applies, refer to our{' '}
              <a href="/pdf/nbn-key-facts-sheet.pdf" target="_blank">
                nbn™ Key Facts
              </a>{' '}
              and{' '}
              <a target="_blank" href="/cis">
                Critical Information Summaries
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
