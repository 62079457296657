import React, { useEffect } from 'react'

import helper from '../services/helper'
import { CheckCoverageBanner, CallToActionMobile } from '../components/mobile'
import { Layout } from '../layouts/Layout'
import { HeroBanner, Showcase, SEO } from '../components/shared'
import { PlanSuggestion } from '../components/shared/plan-suggestion'

const ProductMobile = () => {
  useEffect(() => {
    if (window.innerWidth > 991) {
      helper.syncHeights('.showcase-item h3')
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Pronto Mobile"
        description="High speed nbn™ plans with everything you need to keep your home and business connected."
      />

      <main id="page-product-nbn">
        <HeroBanner variation={4} />

        <Showcase variation={3} />

        <PlanSuggestion pageType="mobile" />

        <CheckCoverageBanner />

        <CallToActionMobile />
      </main>
    </Layout>
  )
}

export default ProductMobile
