import React from 'react'

import { PlanOption } from './PlanOption'
import { usePlanSuggestion } from './usePlanSuggestion'

export const PlanSpeed = () => {
  const {
    currentSelectedPlan,
    currentSpeedIndex,
    currentDataIndex,
    currentVoiceIndex,
    speeds,
    speedsPersonalOnly,
    speedsBusinessOnly,
    speedsDataOnly,
    speedsVoiceDataOnly,
    setSpeed,
    pageType,
    currentTypeIndex,
  } = usePlanSuggestion()

  return (
    <div className="col-md-4">
      {currentSelectedPlan() && (
        <div className="plan">
          <div className="plan-header" style={{ minHeight: '98px' }}>
            <h3>{pageType === 'mobile' ? 'Plan size' : 'Plan speed'}</h3>
            {pageType !== 'mobile' && (
              <p className="text-center text-white blurb">
                All Pronto nbn™ plans come with unlimited data
              </p>
            )}
          </div>
          <div className="plan-body">
            {pageType === 'mobile'
              ? currentTypeIndex === '1'
                ? Object.keys(speedsDataOnly).map(speed => (
                    <PlanOption
                      option={speedsDataOnly[speed]}
                      changeOption={setSpeed}
                      currentIndex={currentDataIndex}
                      index={speed}
                      key={speed}
                    />
                  ))
                : Object.keys(speedsVoiceDataOnly).map(speed => (
                    <PlanOption
                      option={speedsVoiceDataOnly[speed]}
                      changeOption={setSpeed}
                      currentIndex={currentVoiceIndex}
                      index={speed}
                      key={speed}
                    />
                  ))
              : currentTypeIndex === '1'
              ? Object.keys(speedsBusinessOnly).map(speed => (
                  <PlanOption
                    option={speedsBusinessOnly[speed]}
                    changeOption={setSpeed}
                    currentIndex={currentSpeedIndex}
                    index={speed}
                    key={speed}
                  />
                ))
              : Object.keys(speedsPersonalOnly).map(speed => (
                  <PlanOption
                    option={speedsPersonalOnly[speed]}
                    changeOption={setSpeed}
                    currentIndex={currentSpeedIndex}
                    index={speed}
                    key={speed}
                  />
                ))}
          </div>
        </div>
      )}
    </div>
  )
}
