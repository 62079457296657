import React, { useEffect, useState } from 'react'
import { AnexApiClient } from '@anex/common'

const PlanSuggestionContext = React.createContext([{}, () => {}])

const PlanSuggestionProvider = ({ children, pageType }) => {
  const [state, setstate] = useState({
    plans: [],
    speeds: {},
    types: {},
    lengths: {},
    loading: true,
    currentSpeedIndex: 0,
    currentDataIndex: 0,
    currentVoiceIndex: 0,
    currentTypeIndex: 0,
    currentLengthIndex: 0,
    pageType: pageType,
    speedsDataOnly: {},
    speedsVoiceDataOnly: {},
  })

  useEffect(() => {
    async function fetchData() {
      const api = new AnexApiClient(
        window['ANEX_API_ENDPOINT'],
        window['ANEX_API_TOKEN']
      )

      try {
        const response =
          state.pageType === 'mobile'
            ? await api.V1.Lookup.Contracts.Mobile()
            : await api.V1.Lookup.Contracts.Broadband.Nbn()

        const contracts = response.contracts.filter(
          contract => contract.planType !== null
        )

        const lengths = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .map(plan => plan.length)
          )
        )

        const speeds = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .map(plan => plan.speed)
          )
        )

        const speedsPersonalOnly = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .filter(plan => plan.planType === 'Personal')
              .map(plan => plan.speed)
          )
        )

        const speedsBusinessOnly = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .filter(plan => plan.planType === 'Business')
              .map(plan => plan.speed)
          )
        )

        const speedsDataOnly = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .filter(plan => plan.isDataOnly)
              .map(plan => plan.speed)
          )
        )

        const speedsVoiceDataOnly = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .filter(plan => !plan.isDataOnly)
              .map(plan => plan.speed)
          )
        )

        const types = Array.from(
          new Set(
            contracts
              .sort(
                (a, b) => a.currentPricing.sellInc - b.currentPricing.sellInc
              )
              .map(plan => (plan.planType !== null ? plan.planType : null))
          )
        )

        setstate({
          ...state,
          plans: contracts,
          lengths,
          types,
          speeds,
          speedsPersonalOnly,
          speedsBusinessOnly,
          speedsDataOnly,
          speedsVoiceDataOnly,
          loading: false,
        })
      } catch (error) {
        setstate({ ...state, loading: false })
      }
    }

    fetchData()
  }, [])

  return (
    <PlanSuggestionContext.Provider value={[state, setstate]}>
      {children}
    </PlanSuggestionContext.Provider>
  )
}

export { PlanSuggestionContext, PlanSuggestionProvider }
